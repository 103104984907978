import React, { FunctionComponent, useEffect, useState } from 'react'
import Script from 'next/script'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
  Radio,
  RadioGroup,
  Divider,
  Link,
  Stack,
  ModalCloseButton,
  useBreakpointValue,
  Box,
  VStack,
  Center,
} from '@chakra-ui/react'
import AtgForgotPassword from 'composable/components/account/atg-forgot-password'
import AtgLoginPage from 'composable/components/account/atg-login-page'
import { BORDERFREE_ORDER_TRACKING_URL } from 'composable/components/general/constants'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import * as yup from 'yup'
import { useAtgUser } from 'frontastic/contexts'
interface ErrorState {
  orderNumber?: string
  email?: string
}

type OrderStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  finalFocus?: React.RefObject<HTMLElement>
}

const OrderStatusModal: FunctionComponent<OrderStatusModalProps> = ({ isOpen, onClose, finalFocus }) => {
  const [searchOption, setSearchOption] = useState('US')
  const [orderNumber, setOrderNumber] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState<ErrorState>({})
  const [touched, setTouched] = useState({ orderNumber: false, email: false })
  const [showSignInModal, setShowSignInModal] = useState(false)
  const { formatMessage } = useFormat({ name: 'common' })
  const orderNumberError = formatMessage({ id: 'validation.orderNumberRequired' })
  const emailError = formatMessage({ id: 'validation.emailRequired' })
  const [displayStyle, setDisplayStyle] = useState('block')
  const { userSessionData } = useAtgUser()
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)

  const validationSchema = yup.object().shape({
    orderNumber: yup.string().required(formatMessage({ id: 'validation.orderNumberRequired' })),
    email: yup
      .string()
      .email(formatMessage({ id: 'validation.emailValid' }))
      .required(formatMessage({ id: 'validation.emailRequired' })),
  })

  if (userSessionData?.firstName !== undefined) {
    validationSchema.fields.email = yup.string()
  }

  const handleSearchOptionChange = (value) => {
    setSearchOption(value)
    resetForm()
  }
  useEffect(() => {
    if (isOpen) {
      utagLink(
        [
          'link',
          {
            tealium_event: 'order_status',
          },
        ],
        userSessionData?.dxlCountry,
        userSessionData?.dxlCurrency,
      )
    }
  }, [isOpen])

  useEffect(() => {
    if (searchOption === 'US') {
      setDisplayStyle('none')
    } else {
      setDisplayStyle('block')
      const win: any = window
      const container = document.getElementById('bfTTContainer')
      if (container && !container.innerHTML) {
        const intervalId = setInterval(() => {
          if (typeof win.loadTrackingTool === 'function') {
            clearInterval(intervalId)
            win.loadTrackingTool()
          }
        }, 200)

        return () => clearInterval(intervalId)
      } else if (container && container.innerHTML) {
        win.loadTrackingTool()
      }
    }
  }, [searchOption])

  const resetForm = () => {
    setOrderNumber('')
    setEmail('')
    setError({})
    setTouched({ orderNumber: false, email: false })
  }

  const handleInputChange = (field, value) => {
    if (field === 'orderNumber') {
      setOrderNumber(value)
    }
    if (field === 'email') {
      setEmail(value)
    }
    setError({ ...error, [field]: '' })
  }

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true })
    if (!orderNumber.trim() && field === 'orderNumber') {
      setError({ ...error, orderNumber: orderNumberError })
    }
    if (!email.trim() && field === 'email') {
      setError({ ...error, email: emailError })
    }
  }

  const handleUSOrderSubmit = async () => {
    try {
      userSessionData.firstName
        ? await validationSchema.validate({ orderNumber }, { abortEarly: false })
        : await validationSchema.validate({ orderNumber, email }, { abortEarly: false })

      const url = userSessionData.firstName
        ? `/order-detail/order-detail/${orderNumber}?encrypted=false`
        : `/order-detail/order-detail/${orderNumber}?email=${email}&encrypted=false`
      window.location.href = url
      onClose()
    } catch (err) {
      console.log(err)
      const errors = {}
      err.inner.forEach((e) => {
        errors[e.path] = e.message
      })
      setError(errors)
    }
  }

  const openLoginModal = () => {
    setShowSignInModal(true)
  }

  const openLoginModalOnEnter = (event) => {
    if (event?.key === 'Enter') {
      setTimeout(() => {
        setShowSignInModal(true)
      }, 300)
    }
  }

  const closeModal = () => {
    setShowSignInModal(false)
  }

  const viewOrderHistory = () => {
    const url = `/myaccount/myOrderHistory`
    window.location.href = url
    onClose()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (searchOption === 'US') {
      handleUSOrderSubmit()
    }
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (showSignInModal) {
      closeModal()
    }
  }

  const radioLayout = useBreakpointValue({ base: 'column', md: 'row' })
  const isDisabled = userSessionData?.firstName ? !orderNumber : !orderNumber || !email

  if (showSignInModal) {
    return <AtgLoginPage isOpen={showSignInModal} onClose={closeModal} toggleForgotPassword={toggleForgotPassword} />
  }
  if (showForgotPasswordModal) {
    return <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
  }

  return (
    <Modal finalFocusRef={finalFocus} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent width={'34.5rem !important'} mx={2} my={4}>
        <ModalHeader fontSize="1.25rem" textAlign="center" fontWeight={'400'} color="#212529" mt={2} pb={2}>
          {formatMessage({ id: 'text.orderStatus.title' })}
        </ModalHeader>
        <ModalCloseButton size={'lg'} _hover={{ backgroundColor: 'none !important', opacity: '0.7 !important' }} />
        <Box px={6}>
          <Divider borderColor="gray.800" />
        </Box>
        <ModalBody mx={2}>
          <RadioGroup onChange={handleSearchOptionChange} value={searchOption}>
            <Stack
              direction={radioLayout === 'row' ? 'row' : 'column'}
              spacing={{ base: '1rem', lg: '1.75rem' }}
              my={4}
            >
              <Radio value="US" borderColor="black" _focus={{ boxShadow: 'none' }}>
                {formatMessage({ id: 'radio.orderStatus.option1' })}
              </Radio>
              <Radio size="md" value="International" _focus={{ boxShadow: 'none' }}>
                {formatMessage({ id: 'radio.orderStatus.option2' })}
              </Radio>
            </Stack>
          </RadioGroup>
          {searchOption === 'US' ? (
            <form onSubmit={handleSubmit}>
              <Text mt={5} mb={4} fontSize={'.875rem'} fontWeight={'300'}>
                {userSessionData?.firstName
                  ? formatMessage({ id: 'text.orderStatus.subTitle' })
                  : formatMessage({ id: 'text.orderStatus.subTitle2' })}
              </Text>
              <VStack spacing={3}>
                <FormControl isInvalid={touched.orderNumber && !!error.orderNumber}>
                  <Input
                    placeholder={formatMessage({ id: 'label.orderStatus.orderNumber' })}
                    value={orderNumber}
                    onChange={(e) => handleInputChange('orderNumber', e.target.value)}
                    onBlur={() => handleBlur('orderNumber')}
                    isRequired
                    borderRadius={'0px'}
                    border={'1px solid grey'}
                    _focus={{
                      boxShadow: 'none',
                      borderWidth: '2px',
                      borderColor: 'black',
                    }}
                    height={'45px'}
                    fontSize={14}
                    fontWeight={400}
                    paddingTop={4}
                    paddingBottom={5}
                  />
                  <FormErrorMessage>{error?.orderNumber}</FormErrorMessage>
                </FormControl>
                {userSessionData?.firstName ? null : (
                  <FormControl isInvalid={touched.email && !!error.email}>
                    <Input
                      placeholder={formatMessage({ id: 'label.orderStatus.email' })}
                      value={email}
                      onChange={(e) => handleInputChange('email', e.target.value)}
                      onBlur={() => handleBlur('email')}
                      borderRadius={'0px'}
                      border={'1px solid grey'}
                      mt={5}
                      isRequired
                      _focus={{
                        boxShadow: 'none',
                        borderWidth: '2px',
                        borderColor: 'black',
                      }}
                      height={'45px'}
                      fontSize={14}
                      fontWeight={400}
                      paddingTop={4}
                      paddingBottom={5}
                    />
                    <FormErrorMessage>{error?.email}</FormErrorMessage>
                  </FormControl>
                )}
              </VStack>
              <Button
                marginTop={5}
                type="submit"
                w="full"
                borderRadius={0}
                onClick={handleSubmit}
                backgroundColor={'black'}
                fontWeight="700"
                fontSize="medium"
                textAlign="center"
                size={'lg'}
                isDisabled={isDisabled}
                color={'#fff'}
                padding={'.625rem .9375rem'}
                _disabled={{
                  opacity: '0.65',
                }}
                _hover={{
                  backgroundColor: 'none',
                }}
              >
                {formatMessage({ id: 'cta.orderStatus.showOrder' })}
              </Button>

              {userSessionData?.firstName ? (
                <Text textAlign={'center'} mb={4} mt={2}>
                  <Link
                    _hover={{ color: '#6b6b6b' }}
                    textDecoration="underline"
                    onClick={viewOrderHistory}
                    tabIndex={0}
                    href="#"
                  >
                    {formatMessage({ id: 'cta.orderStatus.viewOrderHistory' })}
                  </Link>
                </Text>
              ) : (
                <>
                  <Text mt={4} textAlign={'center'} fontSize={16} tabIndex={0}>
                    {formatMessage({ id: 'text.orderStatus.footerText1' })}
                  </Text>
                  <Text textAlign={'center'} mb={4} mt={2}>
                    <Link
                      _hover={{ color: '#6b6b6b' }}
                      textDecoration="underline"
                      onClick={openLoginModal}
                      tabIndex={0}
                      onKeyDown={openLoginModalOnEnter}
                      aria-label={formatMessage({ id: 'cta.orderStatus.signIn' })}
                    >
                      {formatMessage({ id: 'cta.orderStatus.signIn' })}
                    </Link>
                  </Text>
                </>
              )}
              <Text textAlign={'center'} mb={4} mt={8}>
                {formatMessage({ id: 'text.orderStatus.footerText2' })}
                <Link _hover={{ color: '#6b6b6b' }} fontWeight="bold" href="tel:1.855.746.7395">
                  {formatMessage({ id: 'orderStatus.footerLinks' })}
                </Link>
              </Text>
            </form>
          ) : null}
          <VStack style={{ display: displayStyle, width: '100%' }}>
            <Center>
              <Box
                id="bfTTContainer"
                marginLeft={{ md: '-10px' }}
                width={{ base: '100%', md: '29rem !important' }}
                overflow={'hidden'}
              ></Box>
            </Center>
            <Box>
              <Text size="3xl" mb={2} lineHeight="1.2">
                {' '}
                {formatMessage({ id: 'orderStatus.internationalOrder.title' })}
              </Text>
            </Box>
            <Box py={2}>
              <Text> {formatMessage({ id: 'orderStatus.internationalOrder.info' })}</Text>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
      <Script id="intlTrackScript" async={true} src={BORDERFREE_ORDER_TRACKING_URL}></Script>
    </Modal>
  )
}

export default OrderStatusModal
